import {
  useV1Vault,
  useDepositForm,
  useWithdrawForm,
  useInvalidAddressModal,
  useSwitchChainModal,
  useMerklRewards,
  useRewardsApr,
} from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { tokenAtom, targetChainAtom } from '@apps-orangefi/lib/store'
import { VaultInfo, V1ContractAddresses, category } from '@apps-orangefi/lib/types'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { StrategyVaultTemplate } from '@apps-orangefi/ui/templates'
import { useReadV1Vault, useV1Event } from '@apps-orangefi/wagmi/hooks'
import { useDepositV1, useWithdrawV1 } from '@apps-orangefi/wagmi/hooks'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

import type { ContractWidgetProps } from '@apps-orangefi/ui/molecules/strategy'
import useAlphaTicks from '@apps-orangefi/wagmi/hooks/useAlphaTicks'

type Props = {
  vaultInfo: VaultInfo
  openConnectWalletModal: () => void
  distributorWhiteList: { address: AddressType }[]
  chainId: number
}

export const StrategyProductTemplate = ({
  vaultInfo,
  openConnectWalletModal,
  distributorWhiteList,
  chainId,
}: Props) => {
  const { address: account, isConnected } = useAccount()
  const {
    productContract: contractInfo,
    analyticsData,
    contractProps,
    fetching,
  } = useV1Vault(account, vaultInfo)
  const {
    lowerTick,
    upperTick,
    isFetching: isFetchingTicks,
  } = useAlphaTicks(vaultInfo.VAULT_ADDRESS)

  const vaultAddress = vaultInfo.VAULT_ADDRESS
  const parametersAddress = contractInfo?.PARAMETERS_ADDRESS
  const yieldStart = contractInfo?.YIELD_START

  const contracts: V1ContractAddresses = {
    vaultAddress,
    parametersAddress,
  }

  const { supportedChainIds, defaultChainId } = useAtomValue(targetChainAtom)

  const almAddressList = useMemo(() => {
    return [
      {
        vaultAddress: vaultAddress?.toLowerCase() as AddressType,
        poolAddress: contractInfo?.poolAddress.toLowerCase() as AddressType,
      },
    ]
  }, [contractInfo])
  // const { merklRewardsApr, isLoading } = useRewardsApr(chainId, almAddressList)
  // const rewardsApr = merklRewardsApr[vaultAddress?.toLowerCase() as AddressType] ?? new BN(0)
  // const apr = contractInfo?.feeApr?.plus(rewardsApr) ?? null
  const apr = contractInfo?.feeApr ?? null

  const productInfo = contractInfo
    ? { ...contractInfo, ...analyticsData, ticks: { lowerTick, upperTick }, apr }
    : undefined

  const { tokenAddress, tokenBalance, userPosition, vaultCapacity, currentTokenValue, isFetching } =
    useReadV1Vault(contracts, account)

  const {
    amount: depositAmount,
    setAmount: onSetDepositAmount,
    onDeposit,
    onSetMaxBalance,
    isAllowedWallet,
  } = useDepositForm(
    tokenAddress,
    vaultAddress,
    vaultAddress,
    account,
    tokenBalance,
    distributorWhiteList,
    useDepositV1,
    MODAL_TYPES.TxModal
  )
  const { onShowInvalidAddressModal } = useInvalidAddressModal(isAllowedWallet)
  const { openSwitchChainModal: onSwitchChainModal, isActiveChainSupported } = useSwitchChainModal(
    supportedChainIds,
    defaultChainId,
    () => {}
  )

  const { withdrawableAmount, onWithdraw, onChangeRate } = useWithdrawForm(
    vaultAddress,
    vaultAddress,
    account,
    userPosition?.myPosition?.position,
    userPosition?.myPosition?.lpBalance,
    useWithdrawV1,
    MODAL_TYPES.TxModal
  )

  const token = useAtomValue(tokenAtom)

  const depositProps = useMemo(() => {
    return {
      depositAmount,
      depositCap: vaultCapacity.maxCapacity,
      tokenBalance,
      remainingCapacity: vaultCapacity.maxCapacity.minus(vaultCapacity.totalDeposit),
      onSetDepositAmount,
      onDeposit,
      onSetMaxBalance,
      isShowDepositCap: false,
    }
  }, [depositAmount, vaultCapacity, tokenBalance, onSetDepositAmount, onDeposit, onSetMaxBalance])

  const withdrawProps = useMemo(() => {
    return {
      withdrawableAmount,
      onChangeRate,
      onWithdraw,
    }
  }, [withdrawableAmount, onChangeRate, onWithdraw])

  const wallet = useMemo(() => {
    return {
      onConnect: openConnectWalletModal,
      onSwitchChainModal,
      onShowInvalidAddressModal,
      isConnected,
      isActiveChainSupported,
    }
  }, [
    openConnectWalletModal,
    onSwitchChainModal,
    onShowInvalidAddressModal,
    isConnected,
    isActiveChainSupported,
  ])

  const contractWidgetProps: ContractWidgetProps = {
    deposit: depositProps,
    withdraw: {
      ...withdrawProps,
      isFormAvailable: withdrawableAmount.gt(0),
    },
    wallet,
    isFetching,
    userPosition,
    isClosed: contractInfo?.info.category === category.Closed,
    tokenSymbol: token?.symbol,
    baseTokenPriceUSD: contractInfo?.baseTokenPriceUSD ?? new BN(0),
  }

  const _vaultCapacity = useMemo(() => {
    return {
      ...vaultCapacity,
      tokenSymbol: token?.symbol,
      baseTokenPriceUSD: productInfo?.baseTokenPriceUSD,
    }
  }, [vaultCapacity, token, productInfo])

  return (
    <>
      {!!productInfo && (
        <StrategyVaultTemplate
          productInfo={productInfo}
          contractProps={contractProps}
          contractWidgetProps={contractWidgetProps}
          vaultCapacity={_vaultCapacity}
        />
      )}
    </>
  )
}
