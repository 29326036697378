import { BN } from '@apps-orangefi/lib'
// @ts-expect-error: Package doesn't support typescript
import univ3prices from '@thanpolas/univ3prices'
import { TickMath } from '@uniswap/v3-sdk'
import { formatUnits } from 'viem'
import { LiquidityDistribution } from '@apps-orangefi/ui/molecules/LiquidityDistribution'
import { LPPieChart } from '@apps-orangefi/ui/molecules/LPPieChart'
import { BalancesTable } from '@apps-orangefi/ui/molecules/BalancesTable'
import { GetDopexLpPositionsListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { GetPoolQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { Token } from '@apps-orangefi/lib/types'
import { PlaceHolderLiquidityData } from '@apps-orangefi/ui/molecules/PlaceHolderLiquidityData'

export interface LpdfiAnalyticsProps {
  vaultAddress: `0x${string}` | undefined
  dopexData: GetDopexLpPositionsListQuery | undefined
  dataUniV3: GetPoolQueryQuery | undefined
  baseToken: Token | undefined
  baseTokenPrice: BN | undefined
  baseTokenBalance: BN | undefined
  counterTokenBalance: BN | undefined
}

export interface TickData {
  tickLower: number
  tickUpper: number
  priceLower: number
  priceUpper: number
  liquidity: bigint
  totalSupply: bigint
  liquidityUsed: number
  shares: bigint
  userLiquidity: number
  liquidityRemaining: number
  price: number
  amount0: number
  amount1: number
  token0Name: string
  token1Name: string
}

const getFormattedTicks = (
  dopexData: GetDopexLpPositionsListQuery,
  dataUniV3: GetPoolQueryQuery
) => {
  const tickData: TickData[] = dopexData.lppositions.map(tokenIdData => {
    const tokenIdShares = BigInt(tokenIdData.shares)
    const userLiquidity =
      (tokenIdShares * BigInt(tokenIdData.strike.totalLiquidity)) /
      BigInt(tokenIdData.strike.totalShares)
    const liquidityInUse =
      (BigInt(tokenIdData.strike.usedLiquidity) * tokenIdShares) /
      BigInt(tokenIdData.strike.totalShares)

    const assets = univ3prices.getAmountsForLiquidityRange(
      dataUniV3.pool?.sqrtPrice,
      TickMath.getSqrtRatioAtTick(tokenIdData.strike.tickLower).toString(),
      TickMath.getSqrtRatioAtTick(tokenIdData.strike.tickUpper).toString(),
      userLiquidity.toString()
    )
    let amount0 = BigInt(0)
    let amount1 = BigInt(0)
    if (assets.length > 0) {
      amount0 = BigInt(assets[0].toString() || 0)
    }
    if (assets.length > 1) {
      amount1 = BigInt(assets[1].toString() || 0)
    }
    const priceLower =
      Math.pow(1.0001, tokenIdData.strike.tickLower) *
      10 ** Number(+dataUniV3.pool!.token0.decimals - +dataUniV3.pool!.token1.decimals)
    const priceUpper =
      Math.pow(1.0001, tokenIdData.strike.tickUpper) *
      10 ** Number(+dataUniV3.pool!.token0.decimals - +dataUniV3.pool!.token1.decimals)
    return {
      tickLower: tokenIdData.strike.tickLower,
      tickUpper: tokenIdData.strike.tickUpper,
      priceLower,
      priceUpper,
      liquidity: BigInt(tokenIdData.strike.totalLiquidity),
      totalSupply: BigInt(tokenIdData.strike.totalShares),
      liquidityUsed: Number(liquidityInUse),
      shares: tokenIdShares,
      userLiquidity: Number(userLiquidity),
      liquidityRemaining: Number(userLiquidity - liquidityInUse),
      price: (priceLower + priceUpper) / 2,
      amount0: +formatUnits(amount0, +dataUniV3.pool!.token0.decimals),
      amount1: +formatUnits(amount1, +dataUniV3.pool!.token1.decimals),
      token0Name: dataUniV3.pool!.token0.name,
      token1Name: dataUniV3.pool!.token1.name,
    }
  })
  const sortedTicks = tickData.sort((a, b) => (a.price - b.price > 0 ? 1 : -1))

  return sortedTicks
}

export const padTicksData = (
  tickData: TickData[],
  currentTick: number,
  token0Decimals: number,
  token1Decimals: number,
  token0Name: string,
  token1Name: string
) => {
  const getEmptyTick = (tickLower: number) => {
    const priceLower =
      Math.pow(1.0001, Number(tickLower)) * 10 ** Number(token0Decimals - token1Decimals)
    const priceUpper =
      Math.pow(1.0001, Number(tickLower + tickWidth)) *
      10 ** Number(token0Decimals - token1Decimals)
    const tickData: TickData = {
      tickLower: tickLower,
      tickUpper: tickLower + tickWidth,
      liquidity: BigInt(0),
      totalSupply: BigInt(1),
      liquidityUsed: 0,
      shares: BigInt(0),
      userLiquidity: 0,
      liquidityRemaining: 0,
      price: (priceLower + priceUpper) / 2,
      priceLower,
      priceUpper,
      amount0: 0,
      amount1: 0,
      token0Name,
      token1Name,
    }
    return tickData
  }
  const tickWidth = tickData.length > 0 ? tickData[0].tickUpper - tickData[0].tickLower : 1
  if (tickData.length === 0) return [getEmptyTick(currentTick)]
  const lowerTicks = tickData.map(tick => tick.tickLower)
  const spacings = lowerTicks.map((tick, i) =>
    Math.abs((i < lowerTicks.length - 1 ? lowerTicks[i + 1] : Infinity) - tick)
  )
  const spacing = lowerTicks.length > 2 ? Math.min(...spacings) : 1
  const nearestAllowedTick = spacing * Math.floor(currentTick / spacing)
  const minTick =
    Math.min(tickData.length > 0 ? tickData[0].tickLower : nearestAllowedTick, nearestAllowedTick) -
    spacing * 5
  const maxTick =
    Math.max(
      tickData.length > 0 ? tickData[tickData.length - 1].tickLower : nearestAllowedTick,
      nearestAllowedTick
    ) +
    spacing * 5

  const ticksBefore: TickData[] = []
  for (let i = minTick; i < tickData[0].tickLower; i += spacing) {
    ticksBefore.push(getEmptyTick(i))
  }
  const ticksAfter: TickData[] = []
  for (let i = tickData[tickData.length - 1].tickLower + spacing; i <= maxTick; i += spacing) {
    ticksAfter.push(getEmptyTick(i))
  }
  const formattedTicks = [...ticksBefore, ...tickData, ...ticksAfter]
  return formattedTicks
}

export const LpdfiLiquidityData = ({ analyticsProps }: { analyticsProps: LpdfiAnalyticsProps }) => {
  const { dopexData, dataUniV3, baseToken, baseTokenPrice, baseTokenBalance, counterTokenBalance } =
    analyticsProps

  if (!baseToken || !dataUniV3 || !baseTokenPrice || !dopexData || !dataUniV3.pool) {
    return <PlaceHolderLiquidityData />
  }

  let counterAssetPrice = 0
  if (baseToken.id === dataUniV3.pool?.token0.id) {
    const token1Price = new BN(dataUniV3.pool!.token1Price).toNumber()
    counterAssetPrice = baseTokenPrice.toNumber() / token1Price
  } else {
    const token0Price = new BN(dataUniV3.pool!.token0Price).toNumber()
    counterAssetPrice = baseTokenPrice.toNumber() / token0Price
  }
  const token0Price =
    baseToken.id === dataUniV3.pool!.token0.id ? baseTokenPrice.toNumber() : counterAssetPrice
  const token1Price =
    baseToken.id === dataUniV3.pool!.token1.id ? baseTokenPrice.toNumber() : counterAssetPrice
  const token0Balance =
    baseToken.id === dataUniV3.pool!.token0.id
      ? baseTokenBalance?.toNumber() || 0
      : counterTokenBalance?.toNumber() || 0
  const token1Balance =
    baseToken.id === dataUniV3.pool!.token1.id
      ? baseTokenBalance?.toNumber() || 0
      : counterTokenBalance?.toNumber() || 0

  const tickData: TickData[] = getFormattedTicks(dopexData, dataUniV3)
  const formattedTickData = padTicksData(
    tickData,
    +dataUniV3.pool!.tick,
    +dataUniV3.pool!.token0.decimals,
    +dataUniV3.pool!.token1.decimals,
    dataUniV3.pool!.token0.symbol,
    dataUniV3.pool!.token1.symbol
  )
  const currentPrice =
    Math.pow(1.0001, +dataUniV3.pool!.tick) *
    10 ** Number(+dataUniV3.pool!.token0.decimals - +dataUniV3.pool!.token1.decimals)
  const lpAmount0 = tickData.reduce((sum, tick) => sum + tick.amount0, 0)
  const lpAmount1 = tickData.reduce((sum, tick) => sum + tick.amount1, 0)
  const totalLP = tickData.reduce((sum, tick) => sum + tick.userLiquidity, 0) / 10 ** 18
  const lpAmount0Usd = lpAmount0 * token0Price
  const lpAmount1Usd = lpAmount1 * token1Price

  const token0Name = dataUniV3.pool!.token0.symbol
  const token1Name = dataUniV3.pool!.token1.symbol

  return (
    <div>
      <div className=" dark:bg-gray-850 p-5 rounded-2xl my-4">
        <p className="mb-4">LP Breakdown</p>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-0">
          <LPPieChart
            token0Name={token0Name}
            token1Name={token1Name}
            token0Usd={lpAmount0Usd}
            token1Usd={lpAmount1Usd}
          ></LPPieChart>
          <BalancesTable
            token0Name={token0Name}
            token1Name={token1Name}
            token0Amount={token0Balance}
            token1Amount={token1Balance}
            token0Usd={token0Balance * token0Price}
            token1Usd={token1Balance * token1Price}
            lpBalance={totalLP}
            lpUsd={lpAmount0Usd + lpAmount1Usd}
          />
        </div>
      </div>
      <LiquidityDistribution
        token0Name={token0Name}
        token1Name={token1Name}
        tickData={formattedTickData}
        currentPrice={currentPrice}
      ></LiquidityDistribution>
    </div>
  )
}
