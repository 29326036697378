import {
  useDopexVault,
  usePerformance,
  useInvalidAddressModal,
  useSwitchChainModal,
  useGetMerklProof,
  useDopexEvent,
  useRewardsApr,
  useStrykeRewardAPR,
} from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { tokenAtom, targetChainAtom } from '@apps-orangefi/lib/store'
import { VaultInfo, category } from '@apps-orangefi/lib/types'
import { DistributorList } from '@apps-orangefi/lib/utils'
import { LPDfiVaultTemplate } from '@apps-orangefi/ui/templates'
import { useReadLPDfiVault, useTokenBalance } from '@apps-orangefi/wagmi/hooks'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

type Props = {
  vaultInfo: VaultInfo
  inspectorAddress: AddressType
  reserveProxyAddress: AddressType
  migrationToAddress: AddressType | undefined
  openConnectWalletModal: () => void
  distributorWhiteList: DistributorList
  chainId: number
  distributorAddress?: AddressType | undefined
}

export const LPDfiProductTemplate = ({
  vaultInfo,
  inspectorAddress,
  reserveProxyAddress,
  migrationToAddress,
  openConnectWalletModal,
  distributorWhiteList,
  chainId,
  distributorAddress,
}: Props) => {
  const { address: account, isConnected } = useAccount()
  const { chain } = useAccount()
  const {
    productContract: contractInfo,
    vaultData,
    almAddressList,
    fetching,
    ethPriceUSD,
    dopexData,
    dataUniV3,
  } = useDopexVault(account, vaultInfo)

  const [vaultAddress, yieldStart] = useMemo(() => {
    return [contractInfo?.VAULT_ADDRESS, contractInfo?.YIELD_START]
  }, [contractInfo?.VAULT_ADDRESS, contractInfo?.YIELD_START])

  const { supportedChainIds, defaultChainId } = useAtomValue(targetChainAtom)
  // const chainId = chain?.id ?? defaultChainId!

  const {
    tokenBalance,
    userPosition,
    vaultCapacity,
    currentTokenValue,
    minDepositAssets,
    handlerAddress,
    isFetching,
  } = useReadLPDfiVault(vaultAddress, account, vaultData?.baseToken?.id as AddressType)

  const strykeRewardParam = useMemo(() => {
    const tvl = vaultCapacity.totalDeposit.multipliedBy(
      contractInfo?.baseTokenPriceUSD ?? new BN(0)
    )
    if (!vaultAddress || !vaultInfo.STRYKE_GAUGE_ID) return []
    return [
      {
        vaultAddress,
        strykeGaugeId: vaultInfo.STRYKE_GAUGE_ID,
        tvl,
      },
    ]
  }, [
    vaultInfo.STRYKE_GAUGE_ID,
    vaultCapacity.totalDeposit.toFixed(),
    contractInfo?.baseTokenPriceUSD.toFixed(),
  ])

  // const { merklRewardsApr, isLoading } = useRewardsApr(chainId, almAddressList)
  const { strykeRewardsApr, isFetching: isFetchingStrykeReward } = useStrykeRewardAPR(
    distributorAddress,
    strykeRewardParam
  )

  const rewardsApr = useMemo(() => {
    return (
      strykeRewardsApr.find(reward => reward?.vaultAddress === vaultAddress)?.totalRewardApr ??
      new BN(0)
    )
  }, [JSON.stringify(strykeRewardsApr), vaultAddress])

  const apr = useMemo(() => {
    return contractInfo?.feeApr?.plus(rewardsApr ?? new BN(0)) ?? null
  }, [JSON.stringify(contractInfo), rewardsApr?.toFixed()])

  const productInfo = useMemo(() => {
    return contractInfo ? { ...contractInfo, apr, rewardsApr } : undefined
  }, [contractInfo, apr?.toFixed(), rewardsApr.toFixed()])

  const baseTokenBalance = useTokenBalance({
    account: vaultAddress,
    tokenAddress: (vaultData?.baseToken.id || '') as AddressType,
  }).data?.balance
  const counterToken =
    vaultData?.baseToken.id.toLowerCase() === dataUniV3?.pool?.token0.id.toLowerCase()
      ? dataUniV3?.pool?.token1
      : dataUniV3?.pool?.token0
  const counterTokenBalance = useTokenBalance({
    account: vaultAddress,
    tokenAddress: (counterToken?.id || '') as AddressType,
  }).data?.balance

  const { isAllowedWallet } = useGetMerklProof(distributorWhiteList, account)
  const { onShowInvalidAddressModal } = useInvalidAddressModal(isAllowedWallet)

  const { openSwitchChainModal: onSwitchChainModal, isActiveChainSupported } = useSwitchChainModal(
    supportedChainIds,
    defaultChainId,
    () => {}
  )

  const token = useAtomValue(tokenAtom)

  const depositProps = {
    vaultAddress,
    tokenBalance,
    tokenSymbol: token?.symbol,
    userPosition,
    vaultCapacity,
    minDepositAssets,
    depositTokenAddress: vaultData?.baseToken?.id as AddressType,
    distributorWhiteList,
    vaultVersion: vaultInfo.info.version,
  }

  const withdrawProps = {
    vaultAddress,
    reserveProxyAddress,
    handlerAddress,
    userPosition,
    chainId,
    poolTick: {
      token0: vaultData?.pool.token0,
      token1: vaultData?.pool.token1,
      tick: Number(vaultData?.pool.tick),
    },
    vaultBaseToken: vaultData?.baseToken,
    ethPriceUSD,
    tokenSymbol: token?.symbol,
  }

  const redeemReservedProps = {
    reserveProxyAddress,
    poolAddress: vaultData?.pool.id as AddressType | undefined,
    handlerAddress,
    chainId,
  }

  const wallet = {
    onConnect: openConnectWalletModal,
    onSwitchChainModal,
    onShowInvalidAddressModal,
    isConnected,
    isActiveChainSupported,
  }

  const { performance } = usePerformance(
    vaultAddress,
    yieldStart,
    useDopexEvent,
    !!contractInfo?.info.showAPR,
    contractInfo?.info.aprBaseDays
  )

  const migrationProps = contractInfo?.info.needMigration
    ? {
        tokenAddress: vaultData?.baseToken?.id as AddressType,
        fromWithdrawAddress: vaultAddress,
        toDepositAddress: migrationToAddress,
        lpBalance: userPosition?.myPosition?.lpBalance,
        accountTokenBalance: tokenBalance,
        whiteList: distributorWhiteList,
        onConnect: openConnectWalletModal,
        onSwitchChainModal,
        isConnected,
        isActiveChainSupported,
        handlerAddress,
      }
    : undefined

  const _vaultCapacity = useMemo(() => {
    return {
      ...vaultCapacity,
      tokenSymbol: token?.symbol,
      baseTokenPriceUSD: contractInfo?.baseTokenPriceUSD,
    }
  }, [vaultCapacity, token, contractInfo])

  return (
    <>
      {!!productInfo && (
        <LPDfiVaultTemplate
          productInfo={productInfo}
          contractWidgetProps={{
            deposit: depositProps,
            withdraw: withdrawProps,
            redeemReserved: redeemReservedProps,
            wallet,
            isFetching,
            userPosition,
            isClosed: contractInfo?.info.category === category.Closed,
            tokenSymbol: token?.symbol,
            baseTokenPriceUSD: contractInfo?.baseTokenPriceUSD ?? new BN(0),
          }}
          vaultCapacity={_vaultCapacity}
          performance={performance}
          fetching={{
            ...fetching,
            reward: isFetchingStrykeReward,
          }}
          migrationProps={migrationProps}
          analyticsProps={{
            vaultAddress,
            dopexData,
            dataUniV3,
            baseToken: contractInfo?.baseToken,
            baseTokenPrice: contractInfo?.baseTokenPriceUSD,
            baseTokenBalance,
            counterTokenBalance,
          }}
        />
      )}
    </>
  )
}
