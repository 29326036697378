import CoinsIcon from '@apps-orangefi/assets/images/point/coins.svg'
import ReferralIcon from '@apps-orangefi/assets/images/point/user-plus.svg'
import { BN } from '@apps-orangefi/lib'
import { Score, User } from '@apps-orangefi/lib/types/point'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { OctagonContainer, Button, Stats } from '@apps-orangefi/ui/atoms/point'
import { EarnPanel, ReferralLink } from '@apps-orangefi/ui/molecules/point'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { TwitterShareButton } from 'react-share'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  user: User | undefined
  referralLink: string | undefined
  score: Score | undefined
}

const earnList = [
  {
    label: 'You receive',
    earns: [
      {
        value: '100pt',
        description: 'for each successful referral.',
      },
      {
        value: '10%',
        description: "of your referees' point earnings.",
      },
    ],
  },
  {
    label: 'Referees receive',
    earns: [
      {
        value: '1.1x',
        description: 'increase in their point earnings.',
      },
    ],
  },
]

export const ReferralsTemplate = ({ user, referralLink, score }: Props) => {
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <div className="type-4xl-bold dark:text-orange-500">Refer and Earn</div>
          <div className="type-base-semibold dark:text-white mt-2">
            Refer <span className="dark:text-orange-500">Orange</span> to your friends and boost
            your earning
          </div>
        </div>
        <div className="mt-12 flex flex-col sm:flex-row">
          <ReferralLink linkUrl={referralLink} />
          {referralLink && (
            <TwitterShareButton url={referralLink}>
              <Button
                label="SHARE ON X"
                hideArrow
                className="w-36 min-w-[140px] sm:ml-6 mt-4 sm:mt-0"
              />
            </TwitterShareButton>
          )}
        </div>

        <div className="flex flex-col sm:flex-row mt-8 gap-3 sm:gap-0">
          <OctagonContainer
            size={4}
            className="w-full sm:w-[55%] px-3 py-6 sm:px-8 sm:pt-8 sm:pb-14"
          >
            <div
              className="border-t border-white w-48 top-0 left-8"
              style={{ position: 'absolute' }}
            ></div>
            <div className="flex flex-col">
              <div className="type-xl-semibold dark:text-white pb-4 border-b border-gray-800">
                Your referrals statistics
              </div>
              <div className="flex flex-col sm:flex-row mt-4">
                <Stats
                  label="Total referrals"
                  value={new BN(score?.inviteCount ?? 0).toFormat(0)}
                  iconUrl={ReferralIcon}
                  className=""
                />
                <Stats
                  label="Points Earned"
                  value={new BN(score?.totalPoints ?? 0).toFormat(0)}
                  iconUrl={CoinsIcon}
                  className="mt-0 mt-6 sm:ml-6 sm:mt-0"
                />
              </div>
            </div>
          </OctagonContainer>

          <OctagonContainer
            size={4}
            className="ml-0 sm:ml-6 px-3 py-6 sm:px-8 sm:pt-8 sm:pb-14 w-auto"
          >
            <div
              className="border-t border-white w-48 top-0 left-8"
              style={{ position: 'absolute' }}
            ></div>

            <div className="flex flex-col">
              <div className="type-xl-semibold dark:text-white pb-4 border-b border-gray-800">
                Referral Incentives (Chapter2)
              </div>
              <EarnPanel earnListCollections={earnList} className="mt-4 gap-6 sm:gap-0" />
            </div>
          </OctagonContainer>
        </div>
      </div>
    </>
  )
}
