import { IconInfo } from '@apps-orangefi/ui/molecules/icons'

export const Analytics = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <div
        className={`w-fit type-base-medium dark:text-white px-3 py-2 md:py-3 md:px-4 flex flex-row items-center rounded-lg md:rounded-2xl dark:bg-gray-850`}
      >
        <IconInfo tooltipText={''} size={24} className="mr-2" />
        <span>
          Analytics may not always be 100% accurate. At times, data may be inaccurate due to bugs,
          RPC outages, or issues with 3rd party APIs amoung other potential reasons. If you see any
          discrepancies, please notify our Discord server
        </span>
      </div>
      {children}
    </div>
  )
}
