import ImageAstronautLogo from '@apps-orangefi/assets/images/campaigns/astronaut.svg'
import ImgMobNFTBenefits from '@apps-orangefi/assets/images/campaigns/mob-nft-benefits.svg'
import ImgNFTBenefits from '@apps-orangefi/assets/images/campaigns/nft-benefits.svg'
import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import { UnlockSteps } from '@apps-orangefi/ui/molecules/astronaut'
import { ClaimNFT, ClaimNFTProps } from '@apps-orangefi/ui/molecules/forms'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  claimNFTProps: ClaimNFTProps
}

export const AstronautTemplate = ({ claimNFTProps }: Props) => {
  const { t } = useTranslation()
  const rType = useResponsiveType()
  const isMdOrLarger = rType === responsiveType.MdOrLarger

  return (
    <div className="container flex flex-col py-6 md:py-12 ">
      <div className="flex flex-col md:flex-row w-full items-center">
        <div className="flex flex-col flex-1">
          <h1 className="dark:text-white text-3xl md:text-7xl font-bold leading-9 md:leading-[4.5rem]">
            The Astronaut
          </h1>
          <div className="type-base-medium mt-3 md:mt-5 w-full md:w-3/4">
            🍊 Join Orange Finance, where finance meets flavor! Connect your wallet, add liquidity,
            hold for 28 days, and unlock exclusive NFTs – limited to just 100 pieces! Don&apos;t
            miss your slice of the citrus action!
          </div>
          <div className="mt-5 md:mt-12">
            <UnlockSteps steps={claimNFTProps.steps} />
          </div>
        </div>
        <div className="w-full max-w-[560px] mt-6 nd:mt-0">
          <video
            src={require('../../assets/videos/orange-nft.mp4')}
            autoPlay
            muted
            loop
            className="rounded-4xl"
          />
        </div>
      </div>
      <div className="mt-8 md:mt-12">
        <ClaimNFT {...claimNFTProps} />
      </div>
      <div className="mt-12 md:mt-16 flex flex-col items-center w-full">
        {isMdOrLarger ? (
          <Image src={ImgNFTBenefits} alt="NFT Benefits" className="w-full" />
        ) : (
          <Image src={ImgMobNFTBenefits} alt="NFT Benefits" />
        )}
      </div>
    </div>
  )
}
