import { category, ProductCardProps } from '@apps-orangefi/lib/types'
import { ProductTable } from '@apps-orangefi/ui/organisms'
import { Tab } from '@headlessui/react'
import { get } from 'lodash'
import { useMemo } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  prodcutCardList: (ProductCardProps & { poolAddress: AddressType })[]
  myVaults: AddressType[]
  fetching: boolean
  children?: JSX.Element
}

export const ProductListTemplate = ({ prodcutCardList, myVaults, fetching, children }: Props) => {
  const productionList = useMemo(() => {
    return prodcutCardList.filter(productCard => productCard.category !== category.Closed)
  }, [prodcutCardList])

  // TODO: dont use myVaults, use myPosition instead
  const myVaultList = useMemo(() => {
    return prodcutCardList.filter(
      productCard =>
        myVaults?.includes(productCard.vaultAddress.toLowerCase() as AddressType) ?? false
    )
  }, [prodcutCardList, myVaults])

  const closedList = useMemo(() => {
    return prodcutCardList.filter(productCard => productCard.category === category.Closed)
  }, [prodcutCardList])

  const tabs = useMemo(() => {
    return ['Vaults', 'My Vault', 'Closed']
  }, [])

  return (
    <div>
      <div className="flex flex-col justify-start">
        <Tab.Group>
          <Tab.List className="w-full flex justify-start [&>*:first-child]:ml-0">
            {tabs.map((tab, i) => (
              <Tab
                className={({ selected }) => {
                  const textClass = selected ? 'dark:text-white' : 'dark:text-gray-500'
                  return `type-2xl-semibold ${textClass} focus:outline-none ml-5`
                }}
                key={i}
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="w-full">
            <Tab.Panel>
              {children}
              <div className="my-3 md:my-4">
                <ProductTable prodcutCardList={productionList} fetching={fetching} />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              {children}
              <div className="my-3 md:my-4">
                <ProductTable prodcutCardList={myVaultList} fetching={fetching} />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              {children}
              <div className="my-3 md:my-4">
                <ProductTable prodcutCardList={closedList} fetching={fetching} />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}
