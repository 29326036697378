import Skeleton from 'react-loading-skeleton'

export const PlaceHolderLiquidityData = () => {
  return (
    <div>
      <div className=" dark:bg-gray-850 p-5 rounded-2xl my-4">
        <p className="mb-4">LP Breakdown</p>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-0">
          <div className="border border-gray-750 rounded-2xl grid grid-cols-5 xl:w-4/5">
            <div className="col-span-3 min-h-[150px]">
              <div
                style={{ width: '100%', height: '100%' }}
                className="flex justify-center items-center"
              >
                <Skeleton circle width={100} height={100} />
              </div>
            </div>
            <div className="flex flex-col justify-center col-span-2">
              <div className="w-32 flex items-center border border-gray-750 rounded-2xl w-fit px-4 py-3 justify-around mb-2">
                <div className="dark:bg-gray-700 w-2 h-2 rounded-[50%]"></div>
                <Skeleton circle width={26} height={26} />
                <Skeleton width={60} height={20} />
              </div>
              <div className="w-32 flex items-center border border-gray-750 rounded-2xl w-fit px-4 py-3 justify-around">
                <circle className="dark:bg-gray-500 w-2 h-2 rounded-[50%]"></circle>
                <Skeleton circle width={26} height={26} />
                <Skeleton width={60} height={20} />
              </div>
            </div>
          </div>
          <div className="lg:px-12">
            <div className="grid grid-cols-3 mb-2 px-4">
              <span className="text-sm dark:text-gray-500">ASSET</span>
              <span className="text-sm dark:text-gray-500 text-center">TOKEN AMOUNT</span>
              <span className="text-sm dark:text-gray-500 text-end">VALUE</span>
            </div>
            <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
              <div className="flex">
                <Skeleton circle width={26} height={26} />
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-center">
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-end">
                <Skeleton width={60} height={20} />
              </div>
            </div>
            <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
              <div className="flex">
                <Skeleton circle width={26} height={26} />
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-center">
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-end">
                <Skeleton width={60} height={20} />
              </div>
            </div>
            <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
              <div className="flex">
                <Skeleton circle width={26} height={26} />
                <Skeleton circle width={26} height={26} className="relative right-2" />
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-center">
                <Skeleton width={60} height={20} />
              </div>
              <div className="flex justify-end">
                <Skeleton width={60} height={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" dark:bg-gray-850 p-5 rounded-2xl">
        <div className="flex justify-between mb-4">
          <span>Current Liquidity Distribution</span>
          <Skeleton width={120} height={20} />
        </div>
        <div
          style={{
            width: '100%',
            height: '250px',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
          }}
        >
          <Skeleton width={'100%'} height={250} />
        </div>
      </div>
    </div>
  )
}
