import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { TickData } from '@apps-orangefi/ui/organisms/analytics/LpdfiLiquidityData'

function nFormatter(num: number, digits: number) {
  if (+num === 0) return '0'
  if (+num >= 1e12 || +num <= 10 ** -digits) {
    return (+num).toExponential(digits).toString()
  } else if (+num < 1) {
    return (+num).toFixed(digits)
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

const CustomTooltip = ({
  active,
  payload,
  showUtilization,
}: {
  active: boolean
  payload: any
  showUtilization: boolean
  label: string
}) => {
  if (active && payload && payload.length && payload[0].payload.userLiquidity > 0) {
    const data: TickData = payload[0].payload
    return (
      <div className="custom-tooltip dark:bg-gray-750 rounded-lg border p-4">
        <div className="grid grid-cols-2 gap-x-4">
          <p>Price range:</p>
          <p>
            ({nFormatter(data.priceLower, 5)}) - ({nFormatter(data.priceUpper, 5)})
          </p>
          {showUtilization && (
            <>
              <p>Utilization (%):</p>
              <p>
                {(
                  (100 * Math.min(data.liquidityUsed, data.userLiquidity)) /
                  data.userLiquidity
                ).toFixed(2)}
                %
              </p>
            </>
          )}
          {data.amount0 > 0 && <p>{data.token0Name}:</p>}
          {data.amount0 > 0 && <p>{nFormatter(data.amount0, 4)}</p>}
          {data.amount1 > 0 && <p>{data.token1Name}:</p>}
          {data.amount1 > 0 && <p>{nFormatter(data.amount1, 4)}</p>}
        </div>
      </div>
    )
  }

  return null
}

const renderUtilizationBar = (props: any) => {
  const { x, y, width, height } = props
  return <rect x={x} y={y} width={width} height={height} fill={'#D7A82A'} />
}

const renderCustomBar = (props: any) => {
  const {
    x,
    y,
    width,
    height,
    index,
    currentPrice,
    payload: { priceLower, priceUpper },
  } = props
  const isActive = +currentPrice < +priceUpper && +currentPrice > +priceLower
  const color1 = '#FB5B0D'
  const color2 = '#5FFF8B'
  const color = (priceUpper + priceLower) / 2 < currentPrice ? '#FB5B0D' : '#5FFF8B'

  if (!isActive) {
    return (
      <svg>
        <rect x={x} y={y} width={width} height={height} fill={color} />
      </svg>
    )
  }
  const totalRange = priceUpper - priceLower
  const percentageAtCurrentPrice = ((currentPrice - priceLower) / totalRange) * 100
  return (
    <svg x={x} y={y}>
      <defs>
        <linearGradient id={`grad-${index}`} x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor={color1} />
          <stop offset={`${percentageAtCurrentPrice}%`} stopColor={color1} />
          <stop offset={`${percentageAtCurrentPrice}%`} stopColor={color2} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>
      <rect width={width} height={height} fill={`url(#grad-${index})`} />
    </svg>
  )
}

export const LiquidityDistribution = ({
  token0Name,
  token1Name,
  tickData,
  currentPrice,
  showUtilization = true,
}: {
  token0Name: string
  token1Name: string
  tickData: TickData[]
  currentPrice: number
  showUtilization?: boolean
}) => {
  // Calculate min and max values
  const minPrice = Math.min(...tickData.map(d => d.priceLower))
  const maxPrice = Math.max(...tickData.map(d => d.priceUpper))

  // Generate evenly distributed ticks
  const generateTicks = (min: number, max: number, count: number) => {
    const step = (max - min) / (count - 1)
    return Array.from({ length: count }, (_, i) => min + step * i)
  }

  const xAxisTicks = generateTicks(minPrice, maxPrice, 10).slice(1)
  return (
    <div className=" dark:bg-gray-850 p-5 rounded-2xl">
      <div className="flex justify-between">
        <span>Current Liquidity Distribution</span>
        <span className="flex text-xs">
          <span className="flex items-center">
            <div className="bg-[#5FFF8B] w-2 h-2 rounded-[50%] mr-2"></div>
            {token0Name}
          </span>
          <span className="flex items-center mx-8">
            <div className="bg-[#FB5B0D] w-2 h-2 rounded-[50%] mr-2"></div>
            {token1Name}
          </span>
          {showUtilization && (
            <span className="flex items-center">
              <div className="bg-[#D7A82A] w-2 h-2 rounded-[50%] mr-2"></div>
              Utilized for option
            </span>
          )}
        </span>
      </div>

      <div
        style={{
          width: '100%',
          height: '250px',
        }}
      >
        <ResponsiveContainer className="noselect" width="100%" height="100%" debounce={100}>
          <BarChart
            barCategoryGap={'0%'}
            {...{
              margin: {
                top: 25,
              },
            }}
            data={tickData}
          >
            <CartesianGrid
              id={'bar_chart_container'}
              horizontal={true}
              vertical={false}
              stroke="#383B45"
            />
            <XAxis
              dataKey="price"
              type="number"
              scale="linear"
              allowDataOverflow={true}
              domain={[minPrice, maxPrice]}
              ticks={xAxisTicks}
              tickLine={false}
              tickFormatter={tick => nFormatter(tick, 2)}
            >
              <Label value={`${token0Name}/${token1Name}`} position={'bottom'} offset={5} />
            </XAxis>
            <YAxis tickCount={4} tickLine={false} tickFormatter={tick => nFormatter(tick, 0)} />
            <Tooltip
              cursor={{ fill: 'none' }}
              content={
                <CustomTooltip
                  active={false}
                  payload={[]}
                  showUtilization={showUtilization}
                  label=""
                />
              }
            />

            {showUtilization && (
              <Bar
                dataKey={'liquidityUsed'}
                stackId="a"
                shape={(props: any) => renderUtilizationBar({ ...props, currentPrice, tickData })}
              />
            )}
            <Bar
              dataKey="liquidityRemaining"
              stackId="a"
              shape={(props: any) =>
                renderCustomBar({
                  ...props,
                  currentPrice,
                  tickData,
                })
              }
            />
            <ReferenceLine x={currentPrice} stroke="white" strokeWidth={'2'} strokeDasharray="6 6">
              <Label value={'Current price'} position="top" fill="white" />
            </ReferenceLine>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
