import AnalyticsIcon from '@apps-orangefi/assets/images/icons/analytics.svg'
import OverViewIcon from '@apps-orangefi/assets/images/icons/overview.svg'
import FigureAutomatorPancakeswap from '@apps-orangefi/assets/images/lpdfi/automator-pancakeswap.svg'
import FigureAutomatorSushiswap from '@apps-orangefi/assets/images/lpdfi/automator-sushiswap.svg'
import FigureAutomatorUniswap from '@apps-orangefi/assets/images/lpdfi/automator-uniswap.svg'
import FigureLiquidityManagement from '@apps-orangefi/assets/images/lpdfi/liquidity-managemnt.svg'
import FigureReallocate from '@apps-orangefi/assets/images/lpdfi/reallocate.svg'
import FigureRebalance from '@apps-orangefi/assets/images/lpdfi/rebalance.svg'
import {
  type ContractProp,
  type StrategyVaultInfo,
  type VaultCapacity,
  type VaultPerformance,
  amm,
  strategy,
  SimulateWithdrawProps,
  Token,
} from '@apps-orangefi/lib/types'
import { LinkButton } from '@apps-orangefi/ui/atoms/buttons'
import { VaultFeatures } from '@apps-orangefi/ui/molecules'
import { Strategy } from '@apps-orangefi/ui/molecules/lpdfi'
import {
  VaultSummary,
  StrategyVaultDescription,
  Feature,
  // ContractWidget,
  VaultInfo,
  MerklRewards,
  Audits,
  Auditor,
} from '@apps-orangefi/ui/molecules/strategy'
import { Analytics } from '@apps-orangefi/ui/organisms/analytics'
import {
  LpdfiAnalyticsProps,
  LpdfiLiquidityData,
} from '@apps-orangefi/ui/organisms/analytics/LpdfiLiquidityData'
import {
  LPDFiMigrationForm,
  LPDFiMigrationFormProps,
  ContractWidgetContainer,
  type ContractWidgetContainerProps,
} from '@apps-orangefi/ui/organisms/lpdfi'
import { Tab } from '@headlessui/react'
// import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { memo, useCallback, useMemo } from 'react'

// import type { ContractWidgetProps } from '@apps-orangefi/ui/molecules/strategy/ContractWidget'

export type LPDfiVaultTemplateProps = {
  productInfo: StrategyVaultInfo
  // contractProps: ContractProp[]
  contractWidgetProps: Omit<ContractWidgetContainerProps, 'isDeprecatedVault'>
  vaultCapacity: VaultCapacity
  performance: VaultPerformance
  fetching: {
    subgraph1: boolean
    subgraph2: boolean
    subgraphAll: boolean
    reward: boolean
  }
  migrationProps?: LPDFiMigrationFormProps
  analyticsProps: LpdfiAnalyticsProps
}

export const LPDfiVaultTemplate = ({
  productInfo,
  // contractProps,
  contractWidgetProps,
  vaultCapacity,
  fetching,
  migrationProps,
  analyticsProps,
}: LPDfiVaultTemplateProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const isDeprecatedVault = !!migrationProps
  const auditors = useMemo(() => [Auditor.WatchPug, Auditor.YAudit], [])

  const [
    strategyTitle,
    topic1Title,
    topic1Desc,
    topic2Title,
    topic2Desc,
    topic2Figure,
    topic2Link,
  ] =
    productInfo.info.strategy === strategy.Swap
      ? [
          t('DOCS.STRATEGY_FEATURES.TITLE.SWAP'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_1.1.TITLE'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_1.1.DESC'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_2.1.TITLE'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_2.1.DESC'),
          FigureRebalance,
          'https://orange-finance.gitbook.io/orange-finance/liquidity-vaults/dual-liquidity-vaults/stryke-vaults#id-1.-wide-rebalance',
        ]
      : [
          t('DOCS.STRATEGY_FEATURES.TITLE.NO_SWAP'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_1.1.TITLE'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_1.1.DESC'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_2.2.TITLE'),
          t('DOCS.STRATEGY_FEATURES.TOPIC_2.2.DESC'),
          FigureReallocate,
          'https://orange-finance.gitbook.io/orange-finance/liquidity-vaults/dual-liquidity-vaults/stryke-vaults#id-2.-wide-reallocate',
        ]

  const automatorFigure =
    productInfo?.allocation?.amm === amm.UNISWAP
      ? FigureAutomatorUniswap
      : productInfo?.allocation?.amm === amm.PANCAKESWAP
      ? FigureAutomatorPancakeswap
      : productInfo?.allocation?.amm === amm.SUSHI
      ? FigureAutomatorSushiswap
      : FigureAutomatorUniswap

  return (
    <div className="flex flex-col ">
      <VaultSummary
        vaultInfo={productInfo}
        vaultCapacity={vaultCapacity}
        fetching={fetching}
        onClickBack={useCallback(() => router.push('/arbitrum'), [])}
      />
      <div className="container flex flex-row py-8 md:py-12 border-t border-gray-800 md:border-none">
        <div className="flex flex-col w-full lg:mt-0">
          <Tab.Group>
            <Tab.List className="w-full flex justify-start">
              <Tab>
                {({ selected }) => (
                  <div
                    className={`w-fit type-base-medium dark:text-white px-3 py-2 md:py-3 md:px-4 flex flex-row items-center hover:opacity-80 rounded-lg md:rounded-2xl ${
                      selected ? 'dark:bg-gray-750' : 'dark:bg-gray-850'
                    }`}
                  >
                    <span>Overview</span>
                  </div>
                )}
              </Tab>
              <Tab className="ml-3">
                {({ selected }) => (
                  <div
                    className={`w-fit type-base-medium dark:text-white px-3 py-2 md:py-3 md:px-4 flex flex-row items-center hover:opacity-80 rounded-lg md:rounded-2xl ${
                      selected ? 'dark:bg-gray-750' : 'dark:bg-gray-850'
                    }`}
                  >
                    <span>Analytics</span>
                  </div>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-8 md:mt-10">
              <Tab.Panel>
                <div className="flex flex-row flex-wrap lg:flex-nowrap flex-row-reverse">
                  <div className="flex flex-col box-content md:ml-20 w-full md:max-w-[440px] md:-mt-20">
                    {isDeprecatedVault && <LPDFiMigrationForm {...migrationProps} />}
                    <ContractWidgetContainer
                      contractWidgetProps={{
                        ...contractWidgetProps,
                        isDeprecatedVault,
                      }}
                      productInfo={productInfo}
                    />
                    {/* <MerklRewards rewardsApr={productInfo.rewardsApr} className="mt-3" /> */}
                    {/* <Audits auditors={auditors} className="mt-3" />
                    <VaultInfo contractProps={contractProps} className="mt-3" /> */}
                  </div>
                  <div className="flex flex-col w-full mt-8 md:mt-0">
                    <StrategyVaultDescription
                      info={productInfo.info}
                      altOverview={t('DOCS.OVERVIEW.LPDFI')}
                    />
                    <LinkButton
                      label={t('DOCS.ACTION.READ_MORE')}
                      href="https://orange-finance.gitbook.io/orange-finance/liquidity-vaults/dual-liquidity-vaults/stryke-vaults#x1.4-improved-lp-profitability"
                      className="mt-5"
                    />
                    <VaultFeatures
                      allocation={productInfo.allocation}
                      featureFigure={automatorFigure}
                      className="mt-10"
                    />
                    <section className="pt-10 flex flex-col">
                      <h2 className="type-2xl-semibold dark:text-white">{strategyTitle}</h2>
                      <div className="mt-4">{t('DOCS.STRATEGY_FEATURES.DESCRIPTION')}</div>
                      <div className="flex flex-row gap-3 mt-6">
                        <Strategy
                          title={topic1Title}
                          description={topic1Desc}
                          figureSrc={FigureLiquidityManagement}
                          readMoreLink="https://orange-finance.gitbook.io/orange-finance/liquidity-vaults/dual-liquidity-vaults#id-1.-tick-level-liquidity-management"
                          className="w-1/2"
                        />
                        <Strategy
                          title={topic2Title}
                          description={topic2Desc}
                          figureSrc={topic2Figure}
                          readMoreLink={topic2Link}
                          className="w-1/2"
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <Analytics>
                  <LpdfiLiquidityData analyticsProps={analyticsProps} />
                </Analytics>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  )
}
