import { LeaderboardUser } from '@apps-orangefi/lib/api/point/user'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point/OctagonContainer'
import { LeaderboardTable } from '@apps-orangefi/ui/molecules/LeaderboardTable'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
const tailwindConfig = require('@apps-orangefi/tailwindcss')

const PAGE_SIZE = 50

export const Leaderboard = ({
  chapter1Ranks,
  chapter2Ranks,
  account,
}: {
  chapter1Ranks: LeaderboardUser[]
  chapter2Ranks: LeaderboardUser[]
  account: AddressType | undefined
}) => {
  const [chapter, setChapter] = useState(1)
  const [numUsersDisplayed, setNumUsersDisplayed] = useState(PAGE_SIZE)
  const userChapter1 = chapter1Ranks.find(
    user => user.wallet.toLowerCase() === account?.toLowerCase()
  )
  const userChapter2 = chapter2Ranks.find(
    user => user.wallet.toLowerCase() === account?.toLowerCase()
  )

  const ranksUsed = chapter === 1 ? chapter1Ranks : chapter2Ranks
  const ranksDisplayed = ranksUsed.slice(0, numUsersDisplayed)
  const currentUser = chapter === 1 ? userChapter1 : userChapter2

  return (
    <div className="w-full">
      <div className="mb-12 sm:mb-10">
        <p className="text-2xl font-semibold dark:text-orange-500 mb-2">Leaderboard</p>
        <p>{ranksUsed.length.toLocaleString()} participants</p>
      </div>
      <div className="flex">
        <button
          className={twMerge([
            'skew-x-[-20deg] py-2 px-4 rounded-sm',
            chapter === 1 ? 'dark:bg-orange-500' : 'dark:bg-gray-800',
          ])}
          onClick={() => {
            setChapter(1)
            setNumUsersDisplayed(PAGE_SIZE)
          }}
        >
          <p className="skew-x-[20deg] text-sm">Chapter 1</p>
        </button>
        <button
          className={twMerge([
            'skew-x-[-20deg] py-2 px-4 rounded-sm',
            chapter === 2 ? 'dark:bg-orange-500' : 'dark:bg-gray-800',
          ])}
          onClick={() => {
            setChapter(2)
            setNumUsersDisplayed(PAGE_SIZE)
          }}
        >
          <p className="skew-x-[20deg] text-sm">Chapter 2</p>
        </button>
      </div>
      <div>
        <LeaderboardTable users={ranksDisplayed || []} currentUser={currentUser} />
      </div>

      <div className="w-full">
        <OctagonContainer
          size={3}
          borderColor={tailwindConfig.theme.extend.colors.orange[500]}
          className="w-fit h-fit m-auto mt-12"
        >
          <button
            className={twMerge([
              'flex flex-row items-center px-12 py-5 shadow-[0px_0px_12px_0px_#FB5B0D_inset] rounded-lg',
              numUsersDisplayed >= ranksUsed.length ? 'hidden' : '',
            ])}
            onClick={() => setNumUsersDisplayed(numUsersDisplayed + PAGE_SIZE)}
          >
            <div className="type-base-bold dark:text-white">MORE</div>
          </button>
        </OctagonContainer>
      </div>
    </div>
  )
}
