import { assetTokenList } from '@apps-orangefi/lib/constants'
import Image from 'next/image'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

export const LPPieChart = ({
  token0Name,
  token1Name,
  token0Usd,
  token1Usd,
}: {
  token0Name: string
  token1Name: string
  token0Usd: number
  token1Usd: number
}) => {
  let token0Pct = (100 * token0Usd) / (token0Usd + token1Usd) || 0
  let token1Pct = (100 * token1Usd) / (token0Usd + token1Usd) || 0
  if (token0Pct === 0 && token1Pct === 0) {
    token0Pct = 50
    token1Pct = 50
  }
  const token0Image = assetTokenList.find(token => token.symbol === token0Name)?.logoUrl ?? ''
  const token1Image = assetTokenList.find(token => token.symbol === token1Name)?.logoUrl ?? ''
  const data = [
    { name: token0Name, value: token0Pct },
    { name: token1Name, value: token1Pct },
  ]
  const COLORS = ['#454953', '#797E8B']

  return (
    <div className="border border-gray-750 rounded-2xl grid grid-cols-5 xl:w-4/5">
      <div className="col-span-3 min-h-[150px]">
        <div style={{ width: '100%', height: '100%' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="80%"
                paddingAngle={3}
                strokeWidth={0} // Remove the white border
                cornerRadius={5}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex flex-col justify-center col-span-2">
        <div className="w-36 flex items-center border border-gray-750 rounded-2xl w-fit px-4 py-3 mb-2">
          <div className="dark:bg-gray-700 w-2 h-2 rounded-[50%]"></div>
          <Image src={token0Image} alt={token0Name} className="w-6 h-6 rounded-full mx-2" />
          {token0Pct.toFixed(2)}%
        </div>
        <div className="w-36 flex items-center border border-gray-750 rounded-2xl w-fit px-4 py-3">
          <div className="dark:bg-gray-500 w-2 h-2 rounded-[50%]"></div>
          <Image src={token1Image} alt={token1Name} className="w-6 h-6 rounded-full mx-2" />
          {token1Pct.toFixed(2)}%
        </div>
      </div>
    </div>
  )
}
