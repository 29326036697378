import { BN } from '@apps-orangefi/lib'
import { assetTokenList } from '@apps-orangefi/lib/constants'
import Image from 'next/image'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'

export const BalancesTable = ({
  token0Name,
  token1Name,
  token0Amount,
  token1Amount,
  token0Usd,
  token1Usd,
  lpBalance,
  lpUsd,
}: {
  token0Name: string
  token1Name: string
  token0Amount: number
  token1Amount: number
  token0Usd: number
  token1Usd: number
  lpBalance: number
  lpUsd: number
}) => {
  const token0Image = assetTokenList.find(token => token.symbol === token0Name)?.logoUrl ?? ''
  const token1Image = assetTokenList.find(token => token.symbol === token1Name)?.logoUrl ?? ''
  return (
    <div className="lg:px-12">
      <div className="grid grid-cols-3 mb-2 px-4">
        <span className="text-sm dark:text-gray-500">ASSET</span>
        <span className="text-sm dark:text-gray-500 text-center">TOKEN AMOUNT</span>
        <span className="text-sm dark:text-gray-500 text-end">VALUE</span>
      </div>
      <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
        <div className="flex">
          <Image src={token0Image} alt={token0Name} className="w-6 h-6 rounded-full mr-2" />
          {token0Name}
        </div>
        <span className="text-center">{numberWithShortScale(new BN(token0Amount), 2)}</span>
        <span className="text-end">${numberWithShortScale(new BN(token0Usd), 2)}</span>
      </div>
      <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
        <div className="flex">
          <Image src={token1Image} alt={token1Name} className="w-6 h-6 rounded-full mr-2" />
          {token1Name}
        </div>
        <span className="text-center">{numberWithShortScale(new BN(token1Amount), 2)}</span>
        <span className="text-end">${numberWithShortScale(new BN(token1Usd), 2)}</span>
      </div>
      <div className="grid grid-cols-3 border border-gray-750 px-4 py-3 rounded-2xl mb-2">
        <div className="flex">
          <Image src={token0Image} alt={token0Name} className="w-6 h-6 rounded-full" />
          <Image
            src={token1Image}
            alt={token1Name}
            className="w-6 h-6 rounded-full relative right-2"
          />
          LP
        </div>
        <span className="text-center">{numberWithShortScale(new BN(lpBalance), 2)}</span>
        <span className="text-end">${numberWithShortScale(new BN(lpUsd))}</span>
      </div>
    </div>
  )
}
