import Spaceship from '@apps-orangefi/assets/images/LTIPPSpaceship.svg'
import SpaceshipMobile from '@apps-orangefi/assets/images/LTIPPSpaceshipMobile.svg'
import { BN } from '@apps-orangefi/lib'
import { ClaimStatus } from '@apps-orangefi/lib/types'
import { Button } from '@apps-orangefi/ui/atoms/buttons'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point'
import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import { TVLBar } from '@apps-orangefi/ui/molecules/spaceship'
import { TimeRemainingBar } from '@apps-orangefi/ui/organisms'
import dayjs from 'dayjs'
import { ChevronRight } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { CSSProperties, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  user: AddressType | undefined
  tvl: BN
  userPoints: number
  totalPoints: number
  campaignStart: Date
  campaignEnd: Date
  claimLTIPP: {
    myReward: BN
    claimStatus: ClaimStatus
    onClaim: () => void
  }
}

const width = 130

export const LtippTemplate = ({
  user,
  tvl,
  userPoints,
  totalPoints,
  campaignStart,
  campaignEnd,
  claimLTIPP,
}: Props) => {
  // TODO: component
  const styleVars = {
    width: `${width}px`,
    position: 'absolute',
    '--spotlight-width': `188px`,
    '--spotlight-height': `77px`,
    '--spotlight-left': `-30px`,
  } as CSSProperties

  const milestone1 = 500000
  const milestone2 = 1000000
  const arbReward2 = 150000
  const arbReward1 = 75000

  const percentagePoints = (100 * userPoints) / totalPoints
  const shareText =
    percentagePoints < 0.01 && percentagePoints > 0 ? '< 0.01' : percentagePoints.toFixed(2)
  // let expectedARB = 0
  // if (Number(tvl) > milestone1) {
  //   expectedARB = (arbReward1 * percentagePoints) / 100
  // }
  // if (Number(tvl) > milestone2) {
  //   expectedARB = (arbReward2 * percentagePoints) / 100
  // }
  const { t } = useTranslation()

  const claimStatus = claimLTIPP.claimStatus
  const isEligible = claimLTIPP.myReward.gt(0) && dayjs().utc().isAfter(campaignEnd)
  const claimEnd = claimStatus.endTime
    ? dayjs(claimStatus.endTime).utc().format('MMM D, YYYY HH:mm')
    : null
  const rewardText =
    claimLTIPP.myReward.gt(0) && claimLTIPP.myReward.lt(1)
      ? claimLTIPP.myReward.toFormat(1, BN.ROUND_DOWN)
      : claimLTIPP.myReward.toFormat(0)

  const claimPeirod = dayjs.duration(
    dayjs(claimStatus.endTime).utc().diff(dayjs(campaignEnd).utc())
  )
  const claimPeriodWeeks = Math.floor(claimPeirod.asWeeks())

  return (
    <>
      <div className="flex flex-col w-full pb-24 mt-2 sm:mt-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:flex-row gap-3 sm:py-0 items-start mb-12">
          <div className="flex flex-col gap-3 w-full h-full">
            <OctagonContainer size={2} className="flex sm:hidden flex-row items-center p-3 sm:p-8">
              <div
                className="border-t border-orange-500 top-0 left-6 active-box"
                style={styleVars}
              ></div>
              <div className="flex flex-row justify-between items-center flex-wrap w-full">
                <div className="flex flex-col justify-start items-baseline">
                  <span className="dark:text-gray-500 font-semibold">Chapter 2 Side Event</span>
                  <div className="text-2xl font-semibold">
                    <span className="dark:text-orange-500 mr-2">ARB LTIPP</span>
                    <span className="dark:text-white">Campaign</span>
                  </div>
                </div>
                <div className="w-full">
                  <div className="type-base-medium dark:text-white mt-4 mb-2 max-w-[320px]">
                    Collect Chapter 2 points and earn $ARB rewards! Up to 150K $ARB will be
                    claimable based on your Chapter 2 points share as of September 1st, 2024.
                  </div>
                </div>
                <Link href={'/events/chapter2'} className="dark:text-orange-500 underline mb-16">
                  Go to chapter 2
                </Link>
                <TimeRemainingBar
                  startTime={campaignStart}
                  endTime={campaignEnd}
                ></TimeRemainingBar>
              </div>
            </OctagonContainer>
            <OctagonContainer size={6} className="hidden sm:flex flex-row items-center p-3 sm:p-8">
              <div
                className="border-t border-orange-500 top-0 left-6 active-box"
                style={styleVars}
              ></div>
              <div className="flex flex-row justify-between items-center flex-wrap w-full">
                <div className="flex flex-col justify-start items-baseline">
                  <span className="dark:text-gray-500 font-semibold">Chapter 2 Side Event</span>
                  <div className="text-2xl font-semibold">
                    <span className="dark:text-orange-500 mr-2">ARB LTIPP</span>
                    <span className="dark:text-white">Campaign</span>
                  </div>
                </div>
                <div className="w-full">
                  <div className="type-base-medium dark:text-white mt-4 mb-2 max-w-[300px]">
                    Collect Chapter 2 points and earn $ARB rewards! Up to 150K $ARB will be
                    claimable based on your Chapter 2 points share as of September 1st, 2024.
                  </div>
                </div>
                <Link href={'/events/chapter2'} className="dark:text-orange-500 underline mb-16">
                  Go to chapter 2
                </Link>
                <TimeRemainingBar
                  startTime={campaignStart}
                  endTime={campaignEnd}
                ></TimeRemainingBar>
              </div>
            </OctagonContainer>
          </div>
          <div className="hidden sm:block w-full h-full">
            <Image src={Spaceship} className="w-full" alt="" />
          </div>
          <div className="sm:hidden w-full h-full">
            <Image src={SpaceshipMobile} className="w-full" alt="" />
          </div>
        </div>
        <div
          className={twMerge([
            'flex gap-16',
            claimLTIPP.myReward.eq(0) ? 'flex-col-reverse sm:flex-col' : 'flex-col',
          ])}
        >
          <TVLBar
            tvl={tvl}
            milestone={{ value: new BN(milestone1), reward: arbReward1 }}
            max={{ value: new BN(milestone2), reward: arbReward2 }}
          ></TVLBar>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div>
              <p className="text-2xl font-semibold">Go to chapter 2 and</p>
              <p className="text-2xl font-semibold dark:text-orange-500 mb-4">
                start earning points
              </p>
              <OctagonContainer
                size={3}
                borderColor={tailwindConfig.theme.extend.colors.orange[500]}
                className="w-fit h-fit"
              >
                <Link href={'/events/chapter2'}>
                  <button className="flex flex-row items-center px-5 py-4 shadow-[0px_0px_12px_0px_#FB5B0D_inset] rounded-lg">
                    <div className="type-base-bold dark:text-white">GO TO CHAPTER 2 &nbsp;</div>
                    <ChevronRight className="dark:text-orange-500"></ChevronRight>
                  </button>
                </Link>
              </OctagonContainer>
            </div>
            <div className="p-6 dark:bg-gray-850 rounded-3xl flex flex-col items-center">
              <div className="grid grid-cols-1 gap-2 md:grid-cols-3 text-center mb-6 w-full">
                <div className="md:border-r border-gray-800 flex justify-between md:flex-col">
                  <p className="dark:text-gray-300 mb-2">Chapter2 Points</p>
                  <p>{new BN(Math.floor(userPoints)).toFormat()} pt</p>
                </div>
                <div className="md:border-r border-gray-800 flex justify-between md:flex-col">
                  <p className="dark:text-gray-300 mb-2">Your Share</p>
                  <p>{shareText}%</p>
                </div>
                <div className="flex justify-between md:flex-col">
                  <p className="dark:text-gray-300 mb-2">Expected Rewards</p>
                  <p>{rewardText} ARB</p>
                </div>
              </div>

              {isEligible ? (
                <>
                  {!claimStatus.isClaimed && claimStatus.isExpired ? (
                    <Button
                      label="Claim Expired"
                      disabled
                      className="rounded-3xl dark:bg-gray-700 text-center w-full py-4"
                    />
                  ) : (
                    <Button
                      label={
                        claimStatus.isClaimed
                          ? t('WIDGET.ACTION.CLAIMED')
                          : t('WIDGET.ACTION.CLAIM')
                      }
                      onSubmit={claimLTIPP.onClaim}
                      disabled={claimStatus.isClaimed}
                      className="rounded-3xl hover:opacity-90 text-center w-full py-4"
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    label="Not Claimable"
                    disabled
                    className="rounded-3xl dark:bg-gray-700 text-center w-full py-4"
                  />
                </>
              )}

              {claimEnd && (
                <div className="flex flex-col items-center">
                  <div className="flex flex-row justify-center type-sm-medium dark:text-gray-300 mt-4">
                    <IconInfo />
                    <span className="ml-2">{claimPeriodWeeks}-weeks claiming period</span>
                  </div>
                  <div>Claim end: {claimEnd}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
