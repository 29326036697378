import { BN } from '@apps-orangefi/lib'
import { Token, LeaderboardRow, MyRank, ClaimStatus } from '@apps-orangefi/lib/types'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { ClaimReward } from '@apps-orangefi/ui/molecules/forms'
import { SpaceshipSummary, Leaderboard } from '@apps-orangefi/ui/molecules/spaceship'
import { Dayjs } from 'dayjs'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  leaderboards: LeaderboardRow[]
  tvlList: {
    vaultAddress: AddressType
    productName: string
    baseToken: Token
    quoteToken: Token
    tvl: BN
  }[]
  totalTvl: BN
  expiryTimestamp: Dayjs
  myRank: MyRank | undefined
  claimStatus: ClaimStatus
  milestones: { value: BN; reward: number }[]
  mileStone1AvgReward: number
  onClaim: () => void
  openConnectWalletModal: () => void
  isConnected: boolean
  fetching: {
    dune: boolean
    subgraph: boolean
  }
}

export const SpaceshipTemplate = ({
  leaderboards,
  tvlList,
  totalTvl,
  expiryTimestamp,
  myRank,
  claimStatus,
  milestones,
  mileStone1AvgReward,
  onClaim,
  openConnectWalletModal,
  isConnected,
  fetching,
}: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <div className="flex flex-col">
      <SpaceshipSummary
        totalTvl={totalTvl}
        tvlList={tvlList}
        milestones={milestones}
        expiryTimestamp={expiryTimestamp.toDate()}
        fetching={fetching.subgraph}
      />

      <div className="flex flex-row container py-8 md:py-12 border-t border-gray-800 md:border-none">
        <div className="flex flex-col w-full lg:mt-0">
          <div className="flex flex-row flex-wrap lg:flex-nowrap flex-row-reverse">
            <div className="flex flex-col box-content md:ml-20 w-full md:max-w-[440px]">
              <ClaimReward
                myRank={myRank}
                claimStatus={claimStatus}
                milestones={milestones}
                mileStone1AvgReward={mileStone1AvgReward}
                onSubmit={onClaim}
                campaignEnd={expiryTimestamp}
                onConnect={openConnectWalletModal}
                isConnected={isConnected}
              />
            </div>
            <div className="flex flex-col w-full mt-8 md:mt-0">
              {fetching.dune ? (
                <LineLoader />
              ) : (
                <>
                  <Leaderboard leaderboards={leaderboards} />
                  <div className="type-base-caption dark:text-gray-300 m-1">
                    {t('MODEL.CLAIM.RANKING_NOTICE')}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
