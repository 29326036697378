import { StrykeLP } from '@apps-orangefi/lib/types'
import { ReserveCardContainer } from '@apps-orangefi/ui/organisms/lpdfi'

type Props = {
  account: AddressType | undefined
  strykeLPs: StrykeLP[]
  refetch: () => void
}

export const ReserveTemplate = ({ account, strykeLPs, refetch }: Props) => {
  return (
    <div className="container mt-8 sm:mt-15 px-10 md:px-8">
      <div className="type-3xl-semibold dark:text-white">Batch Reserve Stryke LP</div>
      <div className="flex flex-col sm:flex-row justify-start flex-wrap gap-4 mt-6">
        {strykeLPs.map((strykeLp, i) => {
          return <ReserveCardContainer {...strykeLp} account={account} refetch={refetch} key={i} />
        })}
      </div>
    </div>
  )
}
